import React from 'react';
import './MenuModal.scss';

export interface IMenuModalProps {
	app: HTMLDivElement | null;
	close(): void;
	restart(): void;
}

const MenuModal: React.FC<IMenuModalProps> = (props) => {
	const { close, app, restart } = props;
	return (
		<div className={"modalContainer"}>
			<div className={"modal"}>
				<div className={"modalTitle"}>Settings</div>
				{
					app &&
					<div className={"button"} onClick={async () => {
						!document.fullscreenElement
							? await app.requestFullscreen()
							: await document.exitFullscreen();
					}}
					>
						{!document.fullscreenElement ? "Fullscreen" : "Exit Fullscreen"}
					</div>

				}
				<div className={"button"}
					onClick={() => restart()}
				>
					<div>Restart</div>
				</div>
				<div className={"button"}
					onClick={() => close()}
				>
					<div>Cancel</div>
				</div>
				<div style={{ fontSize: "0.75rem", marginTop: "2rem" }}>an insignificant production</div>
				<div style={{ fontSize: "0.75rem" }}>Just for Charlie</div>
			</div>
		</div>
	);
}

export default MenuModal;
