import React from 'react';
import './Car.scss';

export interface ICarProps {
	color: string;
	x: number;
	spotted: boolean;
	leftHandDrive: boolean;
	onDestroy(): void;
	onSpotted(): void;
}

const Car: React.FC<ICarProps> = (props) => {
	const { color, x, onDestroy, spotted, leftHandDrive, onSpotted } = props;

	if (x > window.innerWidth) {
		onDestroy();
	}

	return (
		<div
			className={"carContainer"}
			style={leftHandDrive ? { left: x } : { right: x, transform: "scaleX(-1)" }}
		>
			{
				!spotted &&
				<div
					className={"car"}
					onTouchStart={() => onSpotted()}
					onMouseDown={() => !navigator.maxTouchPoints ? onSpotted() : null}
				>
					<div className={"roof"} style={{ backgroundColor: color }}>
						<div className={"window"} />
						<div className={"windscreen"} />
					</div>
					<div className={"body"} style={{ backgroundColor: color }}>
						<div className={"tailLight"} />
						<div className={"wheels"}>
							<div className={"backWheel"} >
								<div className={"hubCap"} />
							</div>
							<div className={"frontWheel"} >
								<div className={"hubCap"} />
							</div>
						</div>
						<div className={"headLight"} />
					</div>
				</div>
			}

		</div>


	);
}

export default Car;
