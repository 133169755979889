import React, { useRef, useState } from 'react';
import { colors } from "../App";
import { useInterval } from "../app.hooks";
import './Balloons.scss';

const Balloon: React.FC = () => {
	const x = useRef(Math.floor(Math.random() * window.innerWidth));
	const [y, setY] = useState(0);
	const color = useRef(colors[Math.floor(Math.random() * colors.length)]);
	const [popped, setPopped] = useState(false);

	useInterval(() => {
		setY(y + 5);
	}, 10);

	return (
		<div className={"balloon"} style={{ left: x.current, bottom: y }} onClick={() => setPopped(true)}>
			{
				!popped &&
				<>
					<div className={"top"} style={{ backgroundColor: color.current }}></div>
					<div className={"string"}></div>
				</>
			}
		</div >


	);
}

export default Balloon;
