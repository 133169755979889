import React, { useState } from 'react';
import { colors } from "../App";
import { useInterval } from "../app.hooks";
import Car from "../Car/Car";
import './Track.scss';

export interface ITrackProps {
	leftHandDrive: boolean;
	speed: number;
	frequency: number;
	addToRainbow(color: string): void;
}

export interface ICarItem {
	color: string;
	position: number;
	spotted: boolean;
}

const Track: React.FC<ITrackProps> = (props) => {
	const { leftHandDrive, addToRainbow, speed, frequency } = props;
	const [cars, setCars] = useState<ICarItem[]>([]);

	useInterval(() => {
		if (cars.length < 6) {
			let randomIsColor = Math.floor(Math.random() * 4);
			let randomIndex = Math.floor(Math.random() * colors.length);
			let color = "white";
			if (randomIsColor === 0) {
				color = colors[randomIndex];
			} else if (randomIsColor === 1) {
				color = "black";
			}
			cars.push({ color, position: -150, spotted: false });
			setCars([...cars]);
		}
	}, frequency);

	useInterval(() => {
		cars.forEach((car, index) => car.position += speed);
		setCars([...cars]);
	}, 10);

	function destroy() {
		cars.shift();
		setCars([...cars]);
	}

	function spotted(index: number) {
		cars[index].spotted = true;
		addToRainbow(cars[index].color);
		setCars([...cars]);
	}

	return (
		<div className={"track"}
			style={{
				width: "100vw",
				flexDirection: leftHandDrive ? "row-reverse" : "row"
			}}
		>
			{
				cars.map((car, index) =>
					<Car
						key={index}
						color={car.color}
						x={car.position}
						leftHandDrive={leftHandDrive}
						spotted={car.spotted}
						onDestroy={() => destroy()}
						onSpotted={() => spotted(index)}
					/>
				)
			}
		</div>
	);
}

export default Track;
