import React, { useState } from 'react';
import { useInterval } from "../app.hooks";
import Balloon from "./Balloon";
import './Balloons.scss';

const Balloons: React.FC = () => {
	const [balloons, setBalloons] = useState<number[]>([]);

	useInterval(() => {
		if (balloons.length < 25) {
			balloons.push(balloons.length);
			setBalloons([...balloons]);
		}
	}, 250);

	console.log(balloons);
	return (
		<div className={"balloons"}>
			{
				balloons.map((item) => <Balloon key={item} />)
			}
		</div>


	);
}

export default Balloons;
