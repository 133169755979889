import React, { useEffect, useState } from 'react';
import { IBeforeInstallPromptEvent } from "./beforeinstall";
import './installprompt.scss';

export interface IInstallPromptProps {
	title: string;
	icon: string;
	url: string;
}

export const InstallPrompt: React.FC<IInstallPromptProps> = (props) => {
	const { title, icon, url } = props;
	const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | Event | undefined>(undefined);
	useEffect(() => {
		window.addEventListener('appinstalled', (evt) => {
			console.log('a2hs', 'installed');
		});
		window.addEventListener('beforeinstallprompt', (e) => {
			console.log("BEFOREINSTALLPROMPT REGISTER");
			if (!/Android/i.test(navigator.userAgent)) {
				setPrompt(e);
			}
		});
	}, []);

	async function installApp() {
		let p = prompt as IBeforeInstallPromptEvent;
		if (p) {
			p.prompt();
			const result = await p.userChoice;
			if (result.outcome === 'accepted') {
				console.log('User accepted the A2HS prompt');
			} else {
				console.log('User dismissed the A2HS prompt');
			}
			setPrompt(undefined);
		}
	}

	return (
		prompt ?
			<div className={"installPromptContainer"}>
				<div className={"installPrompt"}>
					<div className={"installDetailsContainer"}>
						<img className={"installIcon"} src={icon} alt={"Install Icon"} />
						<div className={"installDetails"}>
							<div className={"installTitle"}>{title}</div>
							<div className={"installURL"}>{url}</div>
						</div>
						<div
							className={"installClose"}
							onClick={() => setPrompt(undefined)}
						>
							<div>X</div>
						</div>
					</div>
					<div className={"installButtonContainer"}>
						<div
							className={"installButton"}
							onClick={() => installApp()}
						>
							ADD TO HOME SCREEN
				</div>
					</div>
				</div>
			</div>
			: null
	);
};

export default InstallPrompt;