import React, { useRef, useState } from 'react';
import './App.scss';
import Balloons from "./Balloons/Balloons";
import Logo from "./InstallPrompt/InstallIcon.png";
import InstallPrompt from "./InstallPrompt/InstallPrompt";
import MenuModal from "./MenuModal";
import Track from "./Track/Track";

export const colors = [
	"red",
	"yellow",
	"pink",
	"green",
	"purple",
	"orange",
	"blue"
];

const App: React.FC = () => {
	const app = useRef<HTMLDivElement | null>(null);
	const longPress = useRef(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [rainbow, setRainbow] = useState<string[]>([]);
	const [win, setWin] = useState(false);

	function addToRainbow(color: string) {
		if (rainbow.indexOf(color) < 0) {
			rainbow.push(color);
		}
		setRainbow([...rainbow]);
		let isWin = colors.every((item) => rainbow.some((c) => c === item));
		setWin(isWin);
	}

	function restart() {
		setRainbow([]);
		setWin(false);
		setMenuOpen(false);
	}

	return (
		<div
			className={"App"}
			ref={app}
			onContextMenu={(e) => e.preventDefault()}
			style={win ? { gridTemplateRows: "5rem 1fr" } : {}}
		>
			<div className={"titleContainer"}>
				<div
					style={{ fontSize: "2rem" }}
					onTouchStart={() => {
						longPress.current = true;
						setTimeout(() => {
							if (longPress.current) setMenuOpen(true);
						}, 500);
					}}
					onTouchMove={() => longPress.current = false}
					onTouchEnd={() => longPress.current = false}
					onContextMenu={() => !navigator.maxTouchPoints ? setMenuOpen(true) : null}
				>
					Rainbow Car
				</div>

			</div>
			{
				!win &&
				<>
					<Track
						speed={3}
						frequency={2000}
						leftHandDrive={true}
						addToRainbow={(color) => addToRainbow(color)}
					/>
					<Track
						speed={2}
						frequency={3000}
						leftHandDrive={false}
						addToRainbow={(color) => addToRainbow(color)}
					/>
					<Track
						speed={1}
						frequency={5000}
						leftHandDrive={true}
						addToRainbow={(color) => addToRainbow(color)}
					/>
					<div className={"rainbow"}>
						{
							colors.map((color, index) =>
								<div
									key={index}
									className={"rainbowColor"}
									style={{ backgroundColor: color, opacity: rainbow.indexOf(color) > -1 ? 0.25 : 1 }}
								/>
							)
						}
					</div>
				</>
			}
			{
				win &&
				<div style={{ color: "white" }}>
					<div>WIN</div>
					<div
						onClick={() => restart()}
						style={{
							backgroundColor: "grey", color: "white", padding: "1rem", margin: "2rem"
						}}
					>
						Restart
						</div>
				</div>
			}
			{
				menuOpen &&
				<MenuModal
					app={app.current}
					close={() => setMenuOpen(false)}
					restart={() => restart()}
				/>
			}
			{
				win &&
				<Balloons />
			}
			<InstallPrompt
				title={"Rainbow Car"}
				icon={Logo}
				url={"https://rainbowcar.inprod.dev"}
			/>
		</div>
	);
}

export default App;
